import Link from "next/link"

import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"
import { Routes } from "@services/routes"

const usedNamespaces: NamespaceShortcut[] = ["base-layout"]

interface IProps {
  isMarketplace?: boolean
  href?: string
}

/**
 * this component provides the logo with or without link, depending on if its shown on marketplace or not
 *
 * @todo: still warnings about the image or its parent should be static or relative or absolute: "may not render properly with a parent using position:"static". Consider changing the parent style to position:"relative" with a width and height."
 */
const HeaderLogo: React.FC<IProps> = ({ href, isMarketplace }: IProps) => {
  const t = useDynamicTranslation()

  if (isMarketplace) {
    return <div className="header-logo"
      aria-label={t("base-layout", "logo")}
    />
  }

  return <div>
    <Link href={href ? href : Routes.Marketplace}>
      <a title={t("base-layout", "logoLink")} className="header-logo">
      </a>
    </Link>
  </div>

}

export default withDynamicNamespaces<IProps>(HeaderLogo, usedNamespaces)
