import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect } from "react"

import DropdownComponent from "@components/common/DropdownComponent"
import Icon from "@components/common/Icon"
import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { DEFAULT_LOCALE } from "@locales/locales-config"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"
import { LANGUAGES_AVAILABLE } from "config"


const usedNamespaces: NamespaceShortcut[] = ["base-layout"]

/**
 * The component checks if there are more than 2 languages prepared:
 ** added in the locales (defined in i18n.js)
 *
 * If there's only DE and EN, you can switch German to English and vice versa.
 * If there's more than 2, the current language will be displayed and a dropdown menu will appear on hover.
 * If additional languages should be added: @see /doc/translations.md#sprach-dateien-übersetzen
 *
 * @todo when using more than 1 language, test the cookie-issue: https://github.com/vercel/next.js/issues/22375
 */
const LanguageSelector: React.FC = () => {
  const t = useDynamicTranslation()
  const router = useRouter()
  /** technically supported languages */
  const supportedLocales = router?.locales || [DEFAULT_LOCALE]
  const currentLocale = router?.locale || DEFAULT_LOCALE

  /** filtered languages: technically supported AND configured to be offered to the user */
  const resultingLanguages = supportedLocales.filter(lang => LANGUAGES_AVAILABLE.includes(lang))

  // sets a language preference cookie for 100 days. Value and expiration changes with each language change
  // @todo: Cookie must be mentioned in the data protection information
  useEffect(() => {
    const date = new Date()
    const expireMs = 100 * 24 * 60 * 60 * 1000 // 100 days
    date.setTime(date.getTime() + expireMs)
    // set cookie only, if more than one language is available
    if (typeof window !== undefined && resultingLanguages.length > 1) {
      document.cookie = `NEXT_LOCALE=${currentLocale};expires=${date.toUTCString()};path=/`
    }
  }, [currentLocale])

  // return early on "no languages"
  if (resultingLanguages.length === 1) {
    return null
  }

  // returns nothing in case of only 1 language
  // returns direct switch in case there's only 2 languages, probably the standard DE and EN
  if (resultingLanguages.length === 2) {
    return <div className="language-selector">
      {resultingLanguages
        .filter((lang) => lang !== currentLocale)
        .map((lang) => (
          <Link key={lang} href={router.asPath} locale={lang} passHref>
            <span id={lang} className="clickable">
              <span className="non-action-icon"><Icon name="world" size={24} /></span>
              {t("base-layout", "language." + lang)}
            </span >
          </Link >
        ))}
    </div>
  }
  // returns a list if there's more than 2 languages
  else {
    return <div className="language-selector">
      <DropdownComponent
        className={"language-selector__dropdown"}
        title={t("base-layout", "language.change")}
        button={
          <span className="language-selector">
            <span className="non-action-icon"><Icon name="world" size={24} /></span>
            {t("base-layout", "language." + currentLocale)}
          </span>
        }
      >
        {resultingLanguages
          .filter((lang) => lang !== currentLocale)
          .map((lang) => (
            <Link href={router.asPath} locale={lang} key={lang} passHref>
              <a id={lang}>{t("base-layout", "language." + lang)}</a>
            </Link>
          ))}
      </DropdownComponent>
    </div>
  }
}

export default withDynamicNamespaces(LanguageSelector, usedNamespaces)
